import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';

const Sitemap = ({ location: { pathname } }) => (
  <>
    <SEO title="Sitemap" description="Sitemap" />
    <div />
  </>
);

export default Sitemap;

Sitemap.propTypes = {
  location: PropTypes.object.isRequired,
};
